import React from 'react';
import styled from 'styled-components';

import PageBase from '../components/PageBase';
import Container from '../components/Container';
import pdfIcon from '../images/pdf.svg';
import fsg from '../../static/pdf/20220701-NEOFS-FSG.pdf';
import privacyPolicy from '../../static/pdf/20220804_NEO_Website_Privacy_Policy.pdf';

export default function PrivacyStatementAndFSG() {
	return (
		<PageBase title="Privacy Statement and Financial Services Guide">
			<StyledPrivacyStatementAndFSG>
				<div className="privacy-header">
					<Container>
						<h3>Privacy Statement and Financial Services Guide</h3>
						<p>
							NEO Financial Solutions Pty Ltd understands the importance that you attach to your personal information and we are committed
							to protecting your privacy.
						</p>
					</Container>
				</div>
				<div className="privacy-items">
					<Container>
						<div className="privacy-item">
							<a href={privacyPolicy} download className="mobile-download">
								<img src={pdfIcon} alt="PDF" />
								<h4>NEO's Privacy Policy</h4>
							</a>
							<a href={privacyPolicy} download className="desktop-download">
								Download
							</a>
						</div>

						<div className="privacy-item">
							<a href={fsg} download className="mobile-download">
								<img src={pdfIcon} alt="PDF" />
								<h4>NEO's Financial Services Guide (Part 1)</h4>
							</a>
							<a href={fsg} download className="desktop-download">
								Download
							</a>
						</div>
					</Container>
				</div>
			</StyledPrivacyStatementAndFSG>
		</PageBase>
	);
}

const StyledPrivacyStatementAndFSG = styled.div`
	& .privacy-header {
		padding: 50px 0;
		background-color: #004085;

		& .container {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;

			h3 {
				color: white;
				font-size: 32px;
				font-weight: 700;
				line-height: 43px;
			}

			p {
				color: white;
				font-size: 15px;
				line-height: 25px;
				margin-top: 30px;

				@media screen and (min-width: 768px) {
					max-width: 50%;
				}
			}
		}
	}

	& .privacy-items {
		padding: 50px 0;

		& .container {
			@media screen and (min-width: 768px) {
				flex-direction: column;
				width: 60% !important;
			}

			& .privacy-item {
				background-color: #f8f9fb;
				padding: 25px;
				margin-bottom: 35px;
				transition: background-color 200ms ease-in-out;

				@media screen and (min-width: 768px) {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
				}

				&:hover,
				&:focus {
					background-color: #dbf8ff;

					.desktop-download {
						box-shadow: 0 11px 25px 0 #00000024;
					}
				}

				& .mobile-download {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-direction: row;
					text-decoration: none;
					-webkit-tap-highlight-color: transparent;
					@media screen and (min-width: 768px) {
						pointer-events: none;
					}

					h4 {
						color: #192851;
						font-size: 24px;
						font-weight: 800;
						line-height: 31px;
						margin-left: 15px;
					}

					img {
						position: relative;
						top: 5px;
					}
				}

				& .desktop-download {
					display: none;

					@media screen and (min-width: 768px) {
						color: #49667b;
						font-size: 15px;
						font-weight: 700;
						background-color: white;
						border-radius: 100px;
						width: 180px;
						height: 50px;
						display: flex;
						justify-content: center;
						align-items: center;
						text-decoration: none;
						transition: box-shadow 200ms ease-in-out;
					}
				}
			}
		}
	}
`;
